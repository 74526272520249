<template>
  <div class="app-main">
    <div class="xq_banner"><img src="@/assets/img/banner1.png"></div>
    <div class="app-container">
      <div class="position">
        <h2>资质荣誉</h2>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>资质荣誉</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="honor">
        <el-row :gutter="24" >
          <el-col :xs="24" :sm="8" :md="8" :lg="8"> <img src="@/assets/img/honor/3344.jpg" alt=""></el-col>
          <el-col :xs="24" :sm="8" :md="8" :lg="8"> <img src="@/assets/img/honor/4455.jpg" alt=""></el-col>
          <el-col :xs="24" :sm="8" :md="8" :lg="8"> <img src="@/assets/img/honor/1-1ZR0132J2A5.jpg" alt=""></el-col>
          <el-col :xs="24" :sm="8" :md="8" :lg="8"> <img src="@/assets/img/honor/1-1ZR0132P2523.jpg" alt=""></el-col>
          <el-col :xs="24" :sm="8" :md="8" :lg="8"> <img src="@/assets/img/honor/1-1ZR0132R1W3.jpg" alt=""></el-col>
          <el-col :xs="24" :sm="8" :md="8" :lg="8"> <img src="@/assets/img/honor/1-1ZR0132TaW.jpg" alt=""></el-col>
          <el-col :xs="24" :sm="8" :md="8" :lg="8"> <img src="@/assets/img/honor/1-1ZR013253aV.jpg" alt=""></el-col>
          <el-col :xs="24" :sm="8" :md="8" :lg="8"> <img src="@/assets/img/honor/1-1ZR0132635J4.jpg" alt=""></el-col>
          <el-col :xs="24" :sm="8" :md="8" :lg="8"> <img src="@/assets/img/honor/1-1ZR0132959456.jpg" alt=""></el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  methods: {
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
.honor{
  padding: 30px 0;
  img{
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }
}
</style>